.information-container {
    .description-container {
        font-family: $roboto;
        font-size: 17px;
        line-height: 22px;
        color: black;

        h1, h2, h3, h4, h5, h6 {
            margin-bottom: 40px;
        }
        
        &>div,
        p, blockquote, figure, img {
            margin-bottom: 25px;
        }
    }
}