@media screen and (min-width: 1680px) {
	.tickerwrapper {
		ul {
			&.list {
				li {
					span {
						font-size: 75px;
					}
				}
			}
		}
	}
}
@media screen and (min-width: 1499px) {
	.product_image_col {
		.image-col {
			#product-images {
			    width: calc(100% - 175px);
			    margin-left: auto;
			}
		}
	}
}
@media screen and (max-width: 1600px) and (min-width: 1200px) {
	body[class*="product-product"] {
		#main {
			width: calc(100vw - 150px);			
		}
	}
}
@media screen and (max-width: 1910px) {
	.home-latests {
		.module__products {
			.product-layout {
				.product-thumb {
					.details {
						width: 97%;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1885px) {
	.home-latests {
		.module__products {
			.product-layout {
				.product-thumb {
					.details {
						width: 98%;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1875px) {
	.home-latests {
		.module__products {
			.product-layout {
				.product-thumb {
					.details {
						width: 99%;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1855px) {
	.home-latests {
		.module__products {
			.product-layout {
				.product-thumb {
					.details {
						width: 100%;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1799px) {
	.home-latests {
		.title {
			&--title0 {
				left: 3.5em;
			}
			&--title1 {
				left: 4.5em;
			}
		}
	}
}
@media screen and (max-width: 1775px) {
	body {
		&[class*="product-category"] {
			#main {
				padding-left: 18em;
			}
		}
	}
}
@media screen and (max-width: 1695px) {
	.home-latests {
		.title {
			&--title0 {
				left: 3em;
			}
			&--title1 {
				left: 4em;
			}
		}
	}
}
@media screen and (max-width: 1679px) {
	body {
		&[class*="product-category"] {
			#main {
				padding-left: 17em;
				padding-right: 5em;
			}
		}
		&[class*="product-"] {
			.options_row .col-1 {
				display: none;
			}
		}
	}
	.module {
		&__products {
			.product-layout {
				width: 33.3%;
				margin-bottom: 1.5rem;
			}
		}
		.next-category {
			max-width: 100%;
		}
	}
}
@media screen and (max-width: 1600px) {
	.product_image_col {
		.image-col {
			.thumbsContainer {
				top: 5em;
				z-index: 1050;
			}
			.gallery-thumbs {
				.swiper-wrapper {
					display: flex;
				}
			}
		}
	}
	.login-aside, .cart-aside {
		width: 28.53%;
	}
	body {
		&[class*="product-category"] {
			#main {
				padding-right: 3em;
				padding-left: 16em;
			}
		}
	}
}
@media screen and (max-width: 1550px) {
	.home-latests {
		.title {
			&--title0 {
				left: 2.8em;
			}
			&--title1 {
				left: 3.8em;
			}
		}
	}
}
@media screen and (max-width: 1499px) {
	#main {
		padding-right: 110px;
	}
	.header--desktop {
		.logo h1 {
			font-size: 8vw;
		}
	}
	.product_image_col {
		.image-col {
			.thumbsContainer {
				position: relative;
				display: flex;
				justify-content: center;
				top: 0;
			}
			.gallery-thumbs {
				.swiper-wrapper {
					display: -webkit-box;
				}
			}
		}
	}
	.module {
		.next-category {
			padding-bottom: 0;
			a {
				font-size: 340%;
			}
		}
		&__body {
			margin-bottom: 0;
		}
	}
}
@media screen and (max-width: 1485px) {
	body {
		&[class*="product-category"] {
			#main {
				padding-right: 2em;
				padding-left: 15em;
			}
		}
	}
	.cart-aside, .login-aside {
		width: 34%;
	}
}
@media screen and (max-width: 1366px) {
	#main {
		padding-right: 35px;
	}
	.cart-aside, .login-aside {
		width: 38%;
	}
	body{
		&[class*="product-"] {
			.catFilters #column-left .mfilter-box .mfilter-heading-text {
				font-size: 14px;
			}
		}
		body[class*="product-category"] {
			#main {
				padding-left: 14em;
				padding-right: 1em;
			}
		}
	}
}
@media screen and (max-width: 1299px) {
	.cart-aside {
		width: 35%;
	}
	.header {
		&--desktop {
			.logo h1 {
				margin-top: 3vh;
				font-size: 7.5vw;
			}
		}
	}
}
@media screen and (max-width: 1285px) {
	body {
		&[class*="product-category"] {
			#main {
				padding-right: 1em;
				padding-left: 13em;
			}
		}
	}
}
@media screen and (max-width: 1200px) {
	body[class*="product-product"] {
		#main {
			width: calc(100vw - 35px);
			padding-left: 80px;	
		}
	}
	.cart-aside, .login-aside {
		width: 45%;
	}
	.product-buttons-wrap {
		.qty-div {
			width: 100%;
		}
	}
	.product_details_col {
		.product_title {
			font-size: 30px;
		}
	}
	.module {
		.extension-module, .module-related, &__products {
			.product-layout .product-thumb .image>a img {
				min-width: unset;
				min-height: unset;
			}
		}
		&__products {
			justify-content: center;
			.product-layout {
				width: 45%;
			}
		}
	}
	.home-latests {
		.module__products {
			.product-layout {
				width: 42%;
				width: 34vw;
			}
		}
		.title {
			font-size: 50px;
			&--title0 {
				left: 2.5em;
			}
			&--title1 {
				left: 3.5em;
			}
		}
	}
}
@media screen and (max-width: 1135px) {
	body {
		&[class*="product-category"] {
			#main {
				padding-right: 0;
				padding-left: 10em;
			}
		}
	}
}
@media screen and (max-width: 1119px) {
	.home-latests {
		.title {
			&--title0 {
				left: 2em;
			}
			&--title1 {
				left: 3em;
			}
		}
	}
	.header--desktop .logo h1 {
		font-size: 7vw;
	}
}
@media screen and (max-width: 1000px) {
	#main {
		padding: {
			right: 15px;
			left: 15px;
		}
	}	
	iframe {
		max-width: 100%;
		margin: 0 auto;
	}
	body {
		&[class*="product-category"] {
			#main {
				padding-right: 12px;
				padding-left: 12px;
			}
		}
		.header-product-category {
			img {
				display: none;
			}
		}
	}
	#mfilter-content-container {
		label {
			&.sort_label {
				margin-right: .25rem;
				align-self: center;
			}
		}
		.options_row {
			margin-bottom: 1.5rem;
		}
	}
	.cart-aside, .login-aside {
		width: 55%;
	}
	.category-name {
		display: none;
	}
	.category-mobile-name {
		display: block;
		font-family: $astyBlack;
		font-size: 28px;
		color: $black;
	}
	.home-latests {
		.title {
			left: auto;
			position: relative;
			margin-top: 1em;
			transform: none;
		}
		.module__products .product-layout {
			width: 33.3%;
		}
	}
	.sort-box, .limit-box {
		border-radius: 0;
		border-bottom: 1px solid #000;
		font-size: 15px;
	}
	.module {
		&__products {
			justify-content: center;
			.product-layout {
				width: 50%;
			}
		}
	}
}
@media screen and (max-width: 991px) {
	.cart-aside, .login-aside {
		width: 55%;
	}
	body {
		#product-product { 
			.product_image_col {
				.image-col {
					/*display: none;*/
					.thumbsContainer {
						position: absolute;
						top: 0;
						left: 0;
						padding-top: 0;
						z-index: auto;
						display: inline-block;
						float: left;
						margin-left: 0;
						.gallery-thumbs {
							display: flex;
							max-height: 300px;
							overflow-x: hidden;
							overflow-y: auto;
							.swiper-wrapper {
							    display: -webkit-box;
							    display: flex;
							}
						}
					}
				}
				.image-col-mob {
					/*display: block;*/
				}
			}
		}
		#product-images {
			width: calc(100% - 120px);
			overflow: hidden;
			display: inline-block;
			.swiper-pagination {
				width: 100%;
			}
		}
		.module {
			.next-category {
				a {	
					font-size: 9vw;
					line-height: calc(max(23px,7.99vw));
				}
			}
			.extension-module, .module-related, &__products{
				.product-layout {
					.product-thumb {
						.image {
							.special-percent {
								top: 13px;
								right: 5px;
							    transform: translateX(0);
							    font-size: 18px;
							    width: 65px;
							}
						}
						.details {
							/*padding: 10px;*/
						    .product-name {
								font-size: 15px;
								line-height: 18px;
							}
							.product-price {
								.price-current {
				    				font-size: 17px;
								}
							}
						}
					}
				}
			}
		}
	}
	.product_details_col {
		.price-wrap {
			justify-content: center;
		}
	}
	#SuperRight {
		display: none;
	}
}
@media screen and (max-width: 800px) {
	.home-latests {
		.module__products .product-layout {
			width: 50%;
		}
	}
	.module {
		&__body {
			padding-bottom: 0;
		}	
		.extension-module, .module-related, &__products {
			.product-layout {
				.product-thumb {
					.details {
						position: relative;
						.hover-info {
							max-height: inherit;
							padding: 0 5px;
						}
					}
				}
			}
		}	
		&__products {
			.product-layout {
				width: 80%;
			}
		}		
	}
	body[class*="product-product"] {
		#main {
			width: auto;
			padding-left: 12px;
			padding-right: 12px;
		}
	} 
	.panel.panel-default {
		padding: 25px 0;
	}
	.product_details_col .desc-wrap .desc-info {
		padding: 12px;
	}
	.slide0 {
		display: none;
	}
	.slide1 {
		display: block;
	}
}
@media screen and (max-width: 768px) {
	.cart-aside, .login-aside {
		width: 65%;
	}
	#product-category {
		.module__products {
    		.product-price {
    			margin-top: 0;
    		}
    	}
	}
	.product-thumb {
		.image {
			.special-percent {
    			font-size: 15px;
    			width: 48px;
    		}
    	}
	}
	.product_image_col {
		.image-col {
			.thumbsContainer {
    			margin-left: 0;
    		}
    	}
	}
	.header-product-category {
		img {
			display: none;
		}
	}
	#product-product {
		.product_image_col {
			.image-col { 
				.gallery-thumbs {
					max-width: 108px;
					min-width: 108px;
				}
			}
		}
		.product_title {
			text-align: center;
			font-size: 28px;
			margin-top: 1rem;
		}
		.stock-wrap {
			li {
				width: 100%;
				text-align: center;
			}
		}
		.desc-wrap {
			order: 6;
			border: 2px solid $primaryColor;
			margin-top: 1rem;
			text-align: center;
			padding-left: 0;
			padding-right: 0;
			.desc-title {
				padding: 1rem;
				border-bottom: 2px solid $primaryColor;
			}
		}
	}
	.module {			
		&__products {
			.product-layout {
				width: 95%;
			}
		}
	}
}
@media screen and (max-width: 640px) {
	.home-latests {
		.module__products .product-layout {
			width: 100%;
		}
	}
}
@media screen and (max-width: 567px) {
	.cart-aside, .login-aside {
		width: 88%;
	}
	.login-aside {
		&__header {
			.h2, h2 {
				font-size: 30px;
			}
		}
		&__footer {
			margin-top: 3vh;
		}
	}
	.mfilter-free-container {
    	top: 100px !important;
	}
	#product-product {
		.product_title {
			font-size: 22px;
		}
		.product_image_col {
			.image-col { 
				.gallery-thumbs {
					max-width: 70px;
					min-width: 70px;
					img {
						max-width: 70px;
					}
				}
				.thumbsContainer {
					.gallery-thumbs {
						.swiper-wrapper {
						    display: flex;
						    flex-direction: column;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 400px) {
	.module__products {
		.product-layout {
	    	width: 100% !important;
	    }
	}
	.header--mobile {
		.nav-pills .nav-link {
			padding: .5rem;
		}
	}
}