.login-aside {
    height: 100%;
    width: 24.53%;
    position: fixed;
    z-index: $z-index-login-aside;
    top: 0;
    right: 0;
    transform: translateX(100%);
    background-color: $white;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: all .25s ease-in-out;
    border: 2px solid $primaryColor;

    @include thin-scollbar;

    &.show {
        transform: translateX(0);
    }

    &__header {
        padding: 4em 6.36% 2em;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        h2 {
            color: black;
            font-size: 34px;
            font-family: $robotoBold;
            line-height: 17px;
        }

        img {
            width: 25px;
            height: 25px;
        }
    }

    &__form {
        margin-top: 15vh;

        form {
            .form-group {
                padding: 0 9.51%;
                margin-bottom: 27px;

                label {
                    display: block;
                    text-align: center;
                    font-size: 16px;
                    color: black;
                }

                input {
                    border: 1px solid #000;
                    border-radius: 0;
                    background-color: white;
                    color: black;
                    font-family: $roboto;
                    max-width: 75%;
                    margin: 0 auto;

                    &::selection {
                        background-color: $login-aside-primary;
                        color: white;
                    }

                    &[type="submit"] {
                        background-color: $deepRed;
                        margin: 0 auto;
                        color: white;
                        font-size: 17px;
                        font-family: $robotoBold;
                        display: block;
                        line-height: 34px;
                        border-color: $deepRed;

                        &:hover {
                            background-color: $primaryColor;
                            color: white;
                        }
                        &:focus {
                            background-color: $deepRed;
                            opacity: 0.8;
                        }
                    }
                }

                a.forgotten {
                    display: block;
                    text-align: center;
                    font-size: 16px;
                    font-family: $robotoMedium;
                    color: black;
                    margin-top: 14px;

                    &:hover {
                        color: $primaryColor;
                    }
                }
            }
        }
    }

    &__footer {
        padding: 0 14%;
        margin-top: 10vh;

        .control-label {
            color: $primaryColor;
        }

        .register-btn {
            border-radius: 0;
            border: 0;
            font-family: $robotoMedium;
            font-size: 17px;
            line-height: 34px;
            color: $white;
            max-width: 75%;
            margin: 0 auto;
            display: block;
            background-color: $primaryColor;
            &:hover {
                color: $white;
                background-color: $deepRed;
            }
            &:focus {
                background-color: $deepRed;
                opacity: 0.8;
            }
        }

        .register-link {
            margin-top: 12.5px;
            color: black;
            font-family: $robotoMedium;
            font-size: 16px;
            display: block;
            text-align: center;

            &:hover {
                color: $login-aside-primary;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .login-aside {
            width: 100%;

            &__header {
                &.mobile {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 16px 7.94%;
                    border-bottom: 2px solid white;
                    margin-bottom: 0;

                    .title {
                        color: black;
                        font-size: 5vw;
                        line-height: 37px;
                    }

                    .btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 15px;
                        background-color: white;
                        color: black;
                        width: 56px;
                        .fa-angle-left {
                            font-size: 18px;
                        }
                        img {
                            width: auto;
                            height: 18px;
                        }
                    }
                }
            }
            &__form {
                margin-top: 7vh;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .login-aside {
            &__header {
                padding-left: 0;
                padding-right: 0;
                padding-top: 2em;
            }
            &__footer {
                padding-left: 12px;
                padding-right: 12px;
            }
            &__form {
                form .form-group {
                    padding: 0 5.51%;
                }
            }
        }
    }
}