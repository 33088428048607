.aside {
    &--left,
    &--right {
        /*z-index: 1040;*/
        position: fixed;
        top: 0;
        display: flex;
        flex-flow: column wrap;
        height: 100vh;
        width: var(--aside-left-width);
        max-width: var(--aside-left-width);

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    &--left {
        left: 0; 
        .root-category {
            &.show {
                z-index: 9007199254740991;
            }
        }
        &.menu-opened {
            z-index: $z-index-modal + 200;
        }
    }
    &--right {
        z-index: 1040;
        right: 0;
        bottom: 0;
        top: auto;
        height: auto;

        .wrapped {
            background: $white;
            border: 1px solid $primaryColor;
            text-align: center;
            padding: 15px;
            max-width: min-content;
        }

        .row-top {
            display: none;
            .wrapper{
                color: #444;
                padding: 20px 50px;
                text-align: center;
                border-left: 1px solid #ddd;

                .aside-sharing-title {
                    display: block;
                }

                .icon {
                    position: relative;
                    background-color: #ffffff;
                    border-radius: 50%;
                    margin: 10px;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 22px;
                    display: inline-block;
                    align-items: center;
                    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
                    color: #333;
                    text-decoration: none;
                }
                .tooltip {
                    position: absolute;
                    top: 0;
                    line-height: 1.5;
                    font-size: 14px;
                    background-color: #ffffff;
                    color: #ffffff;
                    padding: 5px 8px;
                    border-radius: 5px;
                    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
                    opacity: 0;
                    pointer-events: none;
                    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
                    &::before {
                        position: absolute;
                        content: "";
                        height: 8px;
                        width: 8px;
                        background-color: #ffffff;
                        bottom: -3px;
                        left: 50%;
                        transform: translate(-50%) rotate(45deg);
                        transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
                    }
                }
                .icon:hover {
                    .tooltip {
                        top: -45px;
                        opacity: 1;
                        visibility: visible;
                        pointer-events: auto;
                    }
                    span, .tooltip {
                      text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
                    }
                }

                .facebook:hover,
                .facebook:hover .tooltip,
                .facebook:hover .tooltip::before {
                    background-color: #3b5999;
                    color: #ffffff;
                }

                .twitter:hover,
                .twitter:hover .tooltip,
                .twitter:hover .tooltip::before {
                    background-color: #46c1f6;
                    color: #ffffff;
                }

                .whatsapp:hover,
                .whatsapp:hover .tooltip,
                .whatsapp:hover .tooltip::before {
                    background-color: #25d366;
                    color: #ffffff;
                }

                .linkedin:hover,
                .linkedin:hover .tooltip,
                .linkedin:hover .tooltip::before {
                    background-color: #0e76a8;
                    color: #ffffff;
                }

                .pinterest:hover,
                .pinterest:hover .tooltip,
                .pinterest:hover .tooltip::before {
                    background-color: #c8232c;
                    color: #ffffff;
                }
            }
        }
    }
}

.btn--aside {
    font-family: $robotoBold;
    font-size: 18px;
    width: 100%;
    line-height: 25px;
    border: 1px solid $primaryColor;
    text-transform: uppercase;
    border-radius: 0;
    background-color: $white;
}

.nav-menu {
    &--desktop {
        position: fixed;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        transform: translateX(-100%);
        transition: all .33s linear;
        z-index: $z-index-menu + 10;
        overflow: hidden;
        background-color: white;
        &.show {
            padding-top: 10vh;
            width: calc(var(--aside-left-width) * 3);
            height: 100vh;
            transform: translateX(0);
        }
        @include make-row();
        margin-left: 0;
        margin-right: 0;
        &>.nav-tabs,
        &>.tab-content {
            flex-wrap: nowrap;
            @include make-col(6);
            &:first-child {                
                padding-right: 0;
                padding-top: 8vh;
                border: {
                    bottom: 0;
                    right: 1px outset $primaryColor;
                }
            }
            &:last-child {
                padding-top: 8vh;
                padding-left: 1vw;
            } 
        }
        .nav-lvl2,
        .nav-lvl3 {
            .nav-item {
                a {
                    display: flex;
                    text-transform: uppercase;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding-left: 25px;
                    padding-right: 15px;
                    padding-bottom: 12px;
                    padding-top: 12px;
                    color: $primaryColor;
                    font-size: 1rem;
                    border: 0;
                    font-family: $robotoBold;
                    &:hover {
                        opacity: 0.8;
                        border: 0;
                    }
                }
            }
            &.nav-lvl2 {
                .nav-item {
                    text-align: left;
                }
                .list-inline {
                    padding-left: inherit;
                }
                .after-link {
                    width: auto;
                    display: inline-block;
                    padding: 0;
                    height: auto;
                    font-size: .9em;
                    border-radius: 0;
                    padding: 0 6px;
                    border: 1px solid;
                    border-right: 0;
                    margin-top: 12px;

                    &:hover {
                        border: 1px solid;
                        border-right: 0;
                    }
                }
                a {
                    justify-content: flex-start;
                    font-size: 1rem;
                    color: $primaryColor;
                }
            }
            &.nav-lvl3 {
                .nav-item {
                    text-align: left;
                }
                a {
                    color: $primaryColor;
                    font-size: 1em;                    
                }
            }
        }
    }
}