.product_image_col{
	.image-col {
	    display: flex;
	    flex-direction: row-reverse;
	    justify-content: space-between;
		align-items: flex-start;
		display: inline-block;
		overflow: hidden;
		text-align: center;
		.main-img {
		    a {
		        &:hover, &:focus {
		            cursor: url("../../dist/image/cross.png") 21 21, pointer;
		        }
		    }
		}
	    .thumbsContainer {
	        position: absolute;
			top: 0;

	        .gallery-thumbs {
	        	.swiper-slide {
    				height: auto !important;
    				max-height: unset;
    			}
			}
	        .swiper-button-next, .swiper-button-prev {
	        	display: none;
	        }
	        .swiper-button-next {
	            color: $primaryColor;
	            left: unset;
	            right: calc(100% - 65px);
	            bottom: -10px;
	            top: unset;
	            transform: rotate(90deg);
	        }
	        .swiper-button-prev {
	            color: $primaryColor;
	            left: unset;
	            right: calc(100% - 65px);
	            transform: rotate(90deg);
	            top: 0px;
	            height: 70px;
	        }
	    }
	    .gallery-thumbs {
	        min-height: 150px;
	        max-height: 600px;
	        max-width: 230px;
	        min-width: 230px;

	        .swiper-wrapper {
	            display: -webkit-box;
	        }

	        .swiper-slide {
	            height: 100px;
	            width: 100px;
	            max-height: 100px;
	            margin-right: 8px;
	            display: inline-grid;
	        }	        

	        img {
	            border-bottom: 2px solid #ddd;
	            border-radius: 0;
	            box-shadow: none;
	            opacity: 0.75;
	            max-width: 100px;
	         	cursor: pointer;

	            &:hover {
	                opacity: 1;
	            }
	        }

	        &.active {
	            img {
	                border-color: $primaryColor;
	                opacity: 1;
	            }
	        }
	    }
	}
	.image-col-mob {
		/*display: none;*/
	}
}
.product_details_col {	
	.product_title {
		color: $primaryColor;
		font-size: 37px;
		font-family: $robotoBold;
	}
	.stock-wrap {
		color: $primaryColor;
		font-family: $robotoBold;
		font-size: 16px;
	}
	.price-wrap {
		display: flex;
		align-items: center;
		li {
			.price-old {
				border: 1px solid $primaryColor;
				text-decoration: line-through red;
				color: #BBBBBB;
				color: #666;
				font-size: 23px;
				font-family: $robotoMedium;
				padding: 10px 12px;
			}
			.price-new {
				border: 1px solid $primaryColor;
				color: $primaryColor;
				font-size: 31px;
				font-family: $robotoBlack;
				padding: 5px 12px;
			}
			.special-percent {
				border: 1px solid $deepRed;
				background-color: $deepRed;
				border-radius: 0;
				font-size: 27px;
				font-family: $robotoBlack;
				color: $white;
				padding: 7.5px 12px;
			}
		}
	}

	.desc-wrap {
		.desc-title {
			color: $primaryColor;
			font-family: $robotoBlack;
			font-size: 18px;
		}
		.desc-info {
			color: #202020;
			font-family: $roboto;
			font-size: 16px;
			padding-bottom: 1rem;
			p {
				margin-bottom: 5px;
			}
		}
	}
}
.product-options-wrapper {
	.text-danger {
		color: #D80000;
	}
	.control-label {
		color: #000;
		float: left;
		font-weight: 600;
		margin: {
			right: 1.5em;
			top: 0.5em;
		}
	}
	.color-wrapper, 
	.size-wrapper {
		border: 2px solid $primaryColor;
		padding: 1em 1.5em;
		margin-bottom: 1em;
	} 
	.colors {
		.radio {
		    display: inline-block;
		    margin: 0 0.3rem;
		}
		.img-thumbnail {
		    padding: 2px;
		    cursor: pointer;
		    border-color: #cdcdcd;
		}
		.radio input[type="radio"] {
		    display: none;
		}
	}
	#colori :checked + img,
	#prd-colors :checked + img,
	#prd-colors :checked + span,
	#img-colors :checked + img,
	#img-colors :checked + span {
	    font-weight: bold;
	    color: $primaryColor;
	}
	#img-colors :checked + span,
	#prd-colors :checked + span {
	    border-bottom: 2px solid #797979;
	}
	#img-colors :checked + img,
	#prd-colors :checked + img {
	    border-bottom: 2px solid #797979;
	}
	.color-name {
	    cursor: pointer;
	}
	#size, #prd-sizes {
		.radio input[type="radio"] {
		    display: none;
		}
		:checked + span {
		    background: black;
		    color: white;
		}
	}
	#prd-sizes {
		div {
			display: flex;
			input {
				display: none;
			}
		}
	}
	#prd-colors.colors > div {
	    display: flex;
	    align-items: center;
	    label {
	    	margin-top: 0;
	    }
	}
	.disabledopt {
	    border-color: $black;
	    border-radius: 4px;
	}
	.size-span {
	    background: $white;
	    border-color: $black;
	    color: #838383;
		color: #666;
	    border-bottom: 2px solid #83838A;
	    border-radius: 0;
	    position: relative;
	    font-size: 18px;
	    padding: 8px 0;
	    margin-left: 5px;
	    width: 40px;
	    height: 40px;
	    display: block;
	    cursor: pointer;

	    &:hover {
	        background: $black;
	        color: $white;
	    }

	    &.disabledopt {
	        color: rgba(0, 0, 0, 0.25);
			color: #666;
	        background-color: #ededed;
	        cursor: not-allowed;
	        background: linear-gradient(
	            to top left,
	            rgba(0, 0, 0, 0) 0%,
	            rgba(0, 0, 0, 0) calc(50% - 0.8px),
	            $black 50%,
	            rgba(0, 0, 0, 0) calc(50% + 0.8px),
	            rgba(0, 0, 0, 0) 100%
	        );
	    }
	}
	.radio {
		&.disabledopt {
			.size-span {
		        color: rgba(0, 0, 0, 0.25);
				color: #666;
		        background-color: #ededed;
		        cursor: not-allowed;
		        background: linear-gradient(
		            to top left,
		            rgba(0, 0, 0, 0) 0%,
		            rgba(0, 0, 0, 0) calc(50% - 0.8px),
		            $black 50%,
		            rgba(0, 0, 0, 0) calc(50% + 0.8px),
		            rgba(0, 0, 0, 0) 100%
		        );
		        background-color: #edededa1;
		    }
	    }
	}
}
.product-buttons-wrap {
	.qty-div {
		background-color: $white;
		color: $primaryColor;
		font-size: 18px;
		font-family: $robotoBold;
		padding: 1em 1.5em !important;
		border: 2px solid;
		margin-bottom: 1rem;

		.btn-primary {
			background-color: transparent;
			border-color: transparent;
			color: $primaryColor;
		}
		.form-control {
			background-color: transparent;
			color: $primaryColor;
			text-align: center;
			border: 0;
			border-bottom: 2px solid $white;
		}
	}
	.add_to_cart_wrapper {
		background-color: $primaryColor;
		text-align: center;
		
		display: flex;
		justify-content: space-between;
		.price-inside-add-to-cart {
			padding: 1em;
			span {
				color: $white;
				font-size: 31px;
				font-family: Roboto-Black;
				padding: 5px 12px;
			}
		}

		.btncart {
			padding: 6px 7px 7px;
			padding: 1em 3em;
			color: $white;
			font-size: 18px;
			font-family: $robotoBold;
			border-radius: 0;
			background-color: $deepRed;
			border: 4px solid $deepRed;
			&:hover {
				background-color: $white;
				border: 4px solid $deepRed;
				color: $black;
			}
			@include media-breakpoint-down(sm) {
				padding: 1em;
				font-size: 15px;
			}
		}
	}
}
#product-images .swiper-pagination {
	bottom: unset;
	width: 50%;	
}
#SuperImage {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: $white;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
    }
}
#SuperTop {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    height: 0;
    z-index: 999999;
    cursor: pointer;
}
#SuperLeft {
    margin-top: 0;
    img {
        &:hover, &:focus {
            cursor: url("../../dist/image/crossx.png") 21 21, pointer;
        }
    }
}
#SuperRight {
    width: 120px;
    position: fixed;
    top: 13px;
    overflow: auto;
    height: 100%;
    left: 30px;
    display: block;
    a {
        &:hover,
        &:focus {
        	pointer-events:auto;
            cursor: url("../../dist/image/cross.png") 21 21, pointer;
        }
    }
    img {
        border: 1.5px solid #e1e1e1;
        margin-bottom: 10px;
    }
}
