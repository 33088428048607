.module {
	&__heading {
		margin-bottom: 40px;
		margin-top: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		.title {
			font-family: $robotoCBold;
			font-size: calc(max(22px, 1.82vw));
			color: black;
		}
		@include media-breakpoint-between(xs, md) {
			border-bottom: 1px solid #d3d3d3;
			margin-top: 25px;
			padding-bottom: 25px;
			.title {
				text-transform: uppercase;
				font-size: calc(max(30px, 1.82vw));
			}
		}
	}
	&__body {
		padding-bottom: 3rem;
		margin-bottom: 3rem;
	}
	.category-container {
		@include make-container(3vw);
	}
	&__products {
		.related-title {
			font-size: calc(max(18px, 4vw + 2vh));
			font-family: $astyBlack;
			color: black;
		}
		@include media-breakpoint-up(lg) {
			.product-layout {
				@include make-col(3.6);
			}
		}
		@include media-breakpoint-only(xs) {
			padding-bottom: 25px;

			.product-layout {
				@include make-col(6);
			}
		}
	}
	.owl-carousel {
		position: relative;
		.owl-nav {
			position: relative;
			margin-top: -20%;
			transform: translateY(-50%);
			.owl-next, .owl-prev {
				position: absolute;
				top: -50%;
				transform: translateY(-50%);
				border: 1px solid black;
				padding: 10px 19px!important;
				border-radius: 50%;
				background-color: white;
			}
			.owl-prev {
				left: 0;
			}
			.owl-next {
				right: 0;
			}
		}
	}
	.next-category {
		padding-top: 33px;
		padding-bottom: 33px;
		border: 1px solid black;
		float: left;
		max-width: 90%;
		span, a {
			color: black;
		}
		span {
			font-family: $robotoBold;
		}
		a {
			font-family: $robotoBlack;
			font-size: 380%;
			line-height: calc(max(23px, 10.99vw));
			word-break: break-all;
			transition: all .33s linear;
		}
		&:hover {
			background-color: #DDD;
			a {
				letter-spacing: 2.5vw;
			}
		}
	}
	@include media-breakpoint-between(xs, lg) {
		#special-carousel {
			display: flex;
			flex-flow: column wrap;
			row-gap: 100px;
			column-gap: 10%;
			.owl-thumbs {
				display: none;
			}
		}
	}
	&__products,
	.extension-module,
	.module-related {
		@include media-breakpoint-between(xs, lg) {
			justify-content: space-between;
			column-gap: unset;
		}
		.product-layout {
			user-select: none;
			margin-bottom: 1.5rem;
			.product-thumb {
				position: relative;
				.bigsizes {
					background-color: #3e3e3e;
					color: $white;
					text-align: center;
					font-size: 18px;
					padding: 5px;
					font-family: $robotoMedium;
				}
				.image {
					position: relative;
					&>a {
						display: block;
						width: 100%;
						text-align: center;

						img {
							min-width: 300px;
							min-height: 300px;
						}
					}
					.special-percent {
						position: absolute;
						right: -32px;
						top: 5px;
						transform: translateX(-50%);
						background-color: $deepRed;
						border-radius: 0;
						font-size: 16px;
						height: 31px;
						width: 71px;
						display: flex;
						justify-content: center;
						align-items: center;
						font-family: $robotoCBold;
						color: $white;
					}
				}
				.details {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					margin: 0 auto;
					width: 100%;
					height: auto;
					background-color: #E3E3E3;
					padding-bottom: 5px;
					.btn-cart {
						font-size: 17px;
						font-family: $robotoBlack;
						&:hover {
							color: $deepRed;
						}
					}
					.product-name {
						word-break: keep-all;
						font-size: 17px;
						line-height: 20px;
						font-family: $robotoMedium;
						text-align: center;
						padding: {
							top: 10px;
							left: 5%;
							right: 5%;
						}
						&>a {
							display: block;
							width: 100%;
							color: black;

							&:hover {
								color: black;
							}
						}
					}
					.product-model {
						text-align: center;
						font-family: $robotoMedium;
						font-size: 15px;
						.prefix {
							color: $dustyGray;
						}
						.postfix {
							color: $darkGray;
						}
					}
					.product-price {
						margin-top: 5px;
						display: flex;
						align-items: center;
						justify-content: center;
						column-gap: 20px;
						.price-current {
							font-size: 19px;
							font-family: $robotoBlack;
							color: black;
						}
						.price-old {
							color: black;
							font-family: $robotoBlack;
							font-size: 13px;
							text-decoration: line-through;
							text-decoration-color: red;
						}
					}
					.hover-info {
						max-height: 0;
						transition: max-height .25s linear;
						overflow: hidden;
						padding-right: 5%;
						padding-left: 5%;
					}
				}									
				.button-group {
					position: absolute;
					z-index: 1;
					left: 0;
					opacity: 0;
					transition: all .33s linear;
					@include media-breakpoint-down(md) {
						opacity: 1;
					}
					.btn {
						padding: 5px 8px;
						font-size: 14px;
						background-color: #fff;
						&:hover {
							color: #000;
						}
					}
				}
				&:hover {
					.hover-info {
						display: flex;
						max-height: 65px;
					}
					.button-group { 
						opacity: 1;
					}
				}
			}
		}
	}
	.extension-module {
		.owl-carousel {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}		
		.owl-stage-outer {
			border-top: 1px solid lightgray;
			border-bottom: 1px solid lightgray;
		}
		.owl-stage {
			display: flex;
			flex-flow: row nowrap;
		}		
		.owl-item {
			padding-top: 50px;
			padding-bottom: 50px;
			&:not(:last-child) {
				border-right: 1px solid lightgray;
			}
		}
	}
	&.related-row {
		h2 {
			font-size: calc(max(20px, 1.8vw));
			line-height: calc(max(24px, 2vw));
			color: $primaryColor;
			font-family: $robotoBold;
		}
	}
	.module-related {
		.swiper {
			flex: 1;
		}
		.swiper-wrapper {
			width: 100%;
			min-height: 600px;
		}
		.swiper-slide {
			.product {
				min-height: 580px;
				&__image {
					min-height: 406px;

					a {
						min-height: inherit;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
	#featured-tab {
		border-bottom: none;

		.nav-item {
			position: relative;
			&:first-child {
				.nav-link {
					border-left: 3px solid $primaryColor;
					border-radius: 30px;

					&.active {
						border-left: 3px solid $primaryColor;
						border-radius: 30px;
					}
				}
			}
			&:last-child {
				.nav-link {
					border-right: 3px solid $primaryColor;
					border-radius: 30px;
				}
			}
			.nav-link {
				color: $grey-border;
				font-weight: 600;
				font-size: 0.9rem;
				position: relative;
			}
			.nav-link.active {
				color: #333;
				border-color: transparent;
				background-color: transparent;
			}
		}
		.nav-item:hover {
			.nav-link {
				color: #333;
			}
			.nav-link:focus,
			.nav-link:hover {
				border-color: transparent;
			}
		}
		.nav-item:first-child {
			.nav-link:before {
				content: "";
				position: absolute;
				top: 2px;
				left: 6px;
				width: 70px;
				height: 2px;
				background-color: $primaryColor;
			}
			.nav-link:after {
				content: "";
				position: absolute;
				bottom: 2px;
				left: 6px;
				width: 70px;
				height: 2px;
				background-color: $primaryColor;
			}
		}
		.nav-item:last-child {
			.nav-link:before {
				content: "";
				position: absolute;
				top: 2px;
				right: 6px;
				width: 70px;
				height: 2px;
				background-color: $primaryColor;
			}
			.nav-link:after {
				content: "";
				position: absolute;
				bottom: 2px;
				right: 6px;
				width: 70px;
				height: 2px;
				background-color: $primaryColor;
			}
		}
		.nav-item:not(:last-child):after {
			content: "";
			position: absolute;
			bottom: calc(50% - 2px);
			right: -3px;
			width: 6px;
			height: 6px;
			background-color: $grey-border-thick;
			border-radius: 100%;
		}
    }
}
.home-latests {
	.title {
		color: $black;
		font-size: 62px;
		font-family: $astyB;
		transform: rotate(-90deg);
		position: absolute;
		&--title0 {
			left: 4em;
			margin-top: 2em;
		}
		&--title1 {
			left: 5em;
			margin-top: 5rem;
		}
	}
	.module {
		&__products {
			.product-layout {
				.product-thumb {
					.details {						
						width: 96%;
						width: 100%;
					} 
				}
				@include media-breakpoint-up(lg) {
					width: 35%;
					width: 28vw;
				}
			}
		}
	}		
}
.contactform {
	.form-control {
		border: 0;
		border-radius: 0;
		border-bottom: 2px solid #444;
		margin-bottom: 12px;
		&:focus {
			box-shadow: none;
			outline: 0;
			border-bottom: 2px solid #ddd;
		}
	}
}
.section-bottom {
	.lookbook-title {
		margin: 1em auto;
		font-size: 45px;
		font-family: $astyB;
		color: $black;
	}
}
.slide0 {
	display: block;
}
.slide1 {
	display: none;
}