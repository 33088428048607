.footer--desktop,
.tickerwrapper {
    position: relative;
    z-index: $z-index-modal;
}
.footer {
    &--desktop {
        color: black;
        background-color: $white;
        padding: {
            left: 5.2vw;
            top: 143px;
            right: 5.2vw;
        }
        .col {
            &__title {
                font-family: $robotoBold;
                font-size: 22px;
                margin-bottom: 31px;
            }
            ul {
                li {
                    a {
                        font-family: $robotoMedium;
                        font-size: 17px;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .col-last {
            .row-newsletter {
                .title {
                    font-size: calc(max(20px, 1.66vw));
                    font-family: $astyB;
                }
                #mc_embed_signup {
                    div {
                        &.mce_inline_error {
                            font-size: 13px;
                            position: absolute;
                            top: -2em;
                            background-color: #9e5858!important;
                        }
                        &#mce-success-response {
                            margin: 0 0 1em 0;
                            padding: 5px 10px;
                            background-color: #096131;
                            font-weight: 600;
                            color: $white;
                            font-size: 14px;
                            position: absolute;
                            bottom: 0;
                        }
                    }
                }  
                .input-group {
                    border: 0;
                    border-top: 1px solid black;
                    border-bottom: 1px solid black;
                    border-radius: 0;    
                    .form-control {
                        border: 0;
                        border-right: 1px solid black;
                        border-radius: 0;
                    }
                    .input-group-text {
                        cursor: pointer;
                        border-radius: 0;
                        border: 0;
                        min-width: 20%;
                        font-size: 18px;
                        font-family: $astyB;
                        justify-content: center;
                        background-color: initial;
                    }
                }
                #footer-terms {
                    width: 17px;
                    height: 17px;
                }
            }
            .row-social {
                .title {
                    font-size: calc(max(20px, 1.66vw));
                    line-height: calc(max(24px, 1.86vw));
                    font-family: $astyB;
                }
                .links {
                    a {
                        font-size: 17px;
                        text-decoration: underline;
                    }
                }
            }
            .links {
                a {
                    text-decoration: underline;
                }
            }
        }
        .row-bottom {
            margin-top: 75px;
            border-top: 1px solid $primaryColor;
            padding-top: 22px;
            padding-bottom: 22px;

            a, span {
                font-size: 15px;
                line-height: 18px;
                font-family: $robotoBold;
            }
        }

        @include media-breakpoint-down(md) {
            text-align: center;
            padding-top: 5em;
            z-index: auto;
            .row-bottom {
                margin-top: 50px;
            }
        }
    }
}
.tickerwrapper {
    position: relative;
    width: 99.9%;
    height: 80px;
    overflow: hidden;
    ul.list {
        position: relative;
        display: inline-block;
        list-style: none;
    }    
    ul.list.cloned {
        position: absolute;
        top: 0;
        left: 0;
    }    
    ul.list li {
        float: left;
        padding-left: 2vw;
        font-family: "Arial Black", Gadget, sans-serif;
        font-size: 48pt;
        font-weight: 600;
        text-transform: uppercase;
        span {
            font-size: calc(max(16px, 3.85vw));
            color: black;
            font-family: $astyB;
        }
    }
}
@include media-breakpoint-down(md) {
    footer {
        &.footer-container {
            .row {
                div.col-12 {
                    margin-bottom: 30px;
                    border-bottom: 1px solid #4b4b4b;
                    padding-bottom: 30px;
                    .col__title {
                        text-decoration: underline;
                    }
                    ul {
                        margin-bottom: 0;
                    }
                    &:last-child {
                        border-bottom: 0;
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                }
                &.row-newsletter {                      
                    .input-group {
                        border: none;
                        justify-content: center;
                        input, span {
                            border: 1px solid $black !important;
                            width: 80% !important;
                            margin: 0.25rem 0 !important;
                        }
                        input {
                            flex: initial;
                            text-align: center;
                        }
                        input::placeholder {
                            color:$black;
                            font-family: $astyB;
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
}
