@import 'variables';

@import '../node_modules/bootstrap/scss/bootstrap';

@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../node_modules/@fortawesome/fontawesome-free/scss/core";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular";

@import "../node_modules/owl.carousel/src/scss/owl.carousel";
@import "../node_modules/owl.carousel/src/scss/owl.theme.default";
@import "../node_modules/sweet-dropdown/dist/min/jquery.sweet-dropdown.min.css";
@import "../node_modules/swiper/swiper-bundle.min.css";

@import '../node_modules/hc-offcanvas-nav/src/scss/core';
@import '../node_modules/hc-offcanvas-nav/src/scss/toggle';
@import '../node_modules/hc-offcanvas-nav/src/scss/theme-default';

@import 'mixins';
@import 'fonts';

/* HEADER */
@import './components/header';

/* MODULES */
@import './components/module';
@import './components/information';
@import './components/cart';
@import './components/theme';
@import './components/asides';
@import './components/product';
@import './components/login-aside';

/* FOOTER */
@import './components/footer';

:root {
	--aside-left-width: 180px;
	--aside-right-width: 155px;
}
#main {
    padding-left: var(--aside-left-width);
    padding-right: var(--aside-right-width);
    background-color: $white;
    margin-top: 14vh;
    @include media-breakpoint-down(xl) {
        margin-top: 12vh;
    }
    @include media-breakpoint-down(lg) {
        margin-top: 0;
    }
	@include media-breakpoint-down(md) {
        margin-top: 0vh;
    }
}
body {
	&.no-scroll {
		position: static;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	&[class*="checkout-success"] {
		#content {
			margin-top: calc(max(10px,5vh));
		}
	}
	&[class*="checkout-"] {
		#accordion {
			#collapse-checkout-confirm {
				.buttons {
					text-align: right;
					.float-right {
						float: none !important;
					}
				}
			}
		}
	}
	&[class*="information-"] {
		h1,
		.cont-container {
			color: black;

			.panel-body {
				.row {
					row-gap: 25px;
				}
			}
		}
	}
	&[class*="quickcheckout-checkout"] {
		#content {
			@extend .my-4;
		}

		#quickcheckout-countdown {
			&:empty {
				display: none;
			}
		}
		#quickcheckoutconfirm {
			@extend .my-4;

			.col-sm-6 {
				@extend .my-2;
			}
		}
	}
	&[class*="account-"] {
		#main {
			background-color: $white;
			padding-top: calc(max(25px, 2vh));
			padding-bottom: calc(max(50px, 10vh));

			@include media-breakpoint-between(xs, lg) {
				padding-left: 6vw;
				padding-right: 6vw;
			}
		}
		#content {
			@include media-breakpoint-between(xs, lg) {
				margin-bottom: 25px;

				.well {
					margin-bottom: 25px;
				}

				a[href*="forgotten"] {
					display: block;
					margin-top: 15px;
				}
			}
			form {
				.btn-default {
					background-color: transparent;
					color: black;
					border: 1px solid black;
					border-radius: 0;

					&:focus {
						box-shadow: 0 0 0 .2rem rgba(185, 155, 86, .5);
					}
				}
			}
		}
	}
	@include media-breakpoint-up(lg) {
		&[class*="product-category"],
		&[class*="product-search"],
		&[class*="product-special"],
		&[class*="product-manufacturer"] {
			.langs {
				/*margin-top: -7.5em;*/
			}
			.catFilters {
				#column-left {
					@include make-col(2);
					position: fixed;
					left: 0;
					margin-top: 8vh; 
					background-color: $white; 
					/*z-index: 20000;*/
					.mfilter-box {
						background-color: $white;
						padding-right: 14.5%;
						padding-left: 3.5%;
						overflow: auto;
						max-height: 500px;

						&::-webkit-scrollbar {
						 	width: 8px;
						 	height: 5px;
						}
						&::-webkit-scrollbar-track {
						  	box-shadow: inset 0 0 5px #666;
						  	border-radius: 10px;
						}
						&::-webkit-scrollbar-thumb {
						  	background: #666;
						  	border-radius: 10px;
						}
						.mfilter-heading {
							.mfilter-head-icon {
								filter: brightness(0) !important;
							}
						}
						.mfilter-heading-text {
							font-size: 15px;
						}
					}
					.mfilter-col-input input[type=checkbox],
					.mfilter-col-input input[type=radio] {
						margin-top: -5px;
					}
				}
			}
			header, .category-mobile-name {
				display: none;
			}
			.header-product-category {
				&--desktop {
					position: fixed;
					z-index: -1;
					top: 0;
					left: var(--aside-left-width);
					max-width: calc(100% - var(--aside-left-width));
					.breadcrumb {
						color: black;
						border: 0;
						text-transform: uppercase;
						border-radius: 0;
						margin-bottom: 0;
						a {
							font-family: $robotoMedium;
							color: black;
							line-height: 19px;
						}
					}
					.category-name {
						font-size: calc(max(18px, 3.8vw + 3.75vh));
						font-family: $astyBlack;
						color: black;
						text-align: right;
						text-transform: uppercase; 
						margin-top: -2vh;
						@include media-breakpoint-up(xxl) {
							@include make-col(5.48);
						}
						@include media-breakpoint-down(xl) {
							font-size: calc(max(18px, 3vw + 3.75vh));
						}
					}
				}
			}
			#main {
				min-height: 92.5vh;
				background-color: white;
				z-index: $z-index-menu + 10;
			}
			#filter-trigger {
				margin-top: 8vh;
				border: 1px solid #BDBDBD;
				height: 44px;
			}
			.aside {
				&--left {
					.row-logo {
						display: none;
					}
				}
			}
			.options_row {
				width: 100%;
				padding: 0;
				column-gap: 3.33%;
				margin: {
					top: 0;
					left: 0;
					right: 0;
				}
				.form-group {
					margin-bottom: 0px;
				}
				label {
					margin-bottom: 0px;
				}
				#input-sort {
					width: max-content;
				}
				#input-sort, #input-limit {
					width: max-content;
					flex-grow: 0;
					border-radius: 0;
					appearance: none;
					font-size: 18px;
					text-align: center;
					padding: 0.25rem 0.5rem;
					line-height: 1.5;
					background-color: white;
				}
				.filter-box {
					display: none !important;
				}
				.sort-box, .limit-box {
					height: 46px;
					background-color: #E3E3E3;
					.form-group {
						margin-top: 0;
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					label {
						margin-top: 0;
						color: black;
						font-family: $robotoBold;
						font-size: 16px;
					}
				}
				.sort-box {
					display: inline-block;
					min-width: 375px;
					border-right: 2px solid white;
				}
				.limit-box {
					display: inline-block;
					min-width: 181px;
				}
				select {
					box-shadow: none;
					outline: none;
					border: 0px;
					border-radius: 0px;
					width: max-content;
					margin-left: auto !important;
					&:focus {
						box-shadow: none;
						border-color: $primaryColor;
						outline: none;
					}
				}
				.col-1 {
					width: 10%;
				}
				@include media-breakpoint-between(xs, lg) {
					.filter-box {
						order: 3;
						width: 100%;
						margin-top: 5px;
						padding-top: 10px;
						padding-bottom: 10px;
					}
					.sort-box {
						order: 1;
						border-right: 2.5px solid white !important;
					}
					.limit-box {
						order: 2;
					}
					.sort-box,
					.limit-box {
						min-width: initial;
						border-right: 0;
						.input-group {
							flex-direction: row;
							flex-wrap: nowrap;
						}
						label,
						select {
							font-size: 12.5px !important;
						}
					}
				}
			}
		}
		&[class*="product-category"] {
			#main {
				padding-left: 20em;
				padding-right: 0;
				margin-top: 16vh;
			}
		}
		&[class*="product-product"] {
			header, .aside--left .row-logo {
				display: none;
			}
			.langs {
				margin-top: 0;
			}
			#main {
				margin-top: 13vh;
			}
			.aside--right .row-top {
				display: flex;
			}
			.header-product-category {
				&--desktop {
					position: fixed;
					z-index: -1;
					top: 0;
					left: var(--aside-left-width);
					max-width: calc(100% - var(--aside-left-width));
					.breadcrumb {
						color: black;
						border: 0;
						text-transform: uppercase;
						border-radius: 0;
						margin-bottom: 0;
						border-bottom: 1px solid $primaryColor;
						a {
							font-family: $robotoMedium;
							color: black;
							line-height: 19px;
							font-size: 16px;
						}
					}
					.category-name {
						font-size: calc(max(18px, 5vw + 5vh));
						font-family: $astyBlack;
						color: black;
						text-align: right;
						text-transform: uppercase;
						@include media-breakpoint-up(lg) {
							@include make-col(5.48);
						}
					}
				}
			}
			.tickerwrapper {
				margin-top: 2em;
			}
		}
	}
	@include media-breakpoint-up(xl) {
		&[class*="product-search"] {
			.module {
				&__products {
					.product-layout {
						width: auto;
						width: 32.33333%;
					}
				}
			}
		}
	}
	#error-not-found {
		margin-top: 25vh;
		margin-bottom: 7vh;
	}
	&.product-manufacturer {
		.product-manufacturer-list {
			margin-bottom: 50px;
			h2 {
				margin-bottom: 25px;
			}
			.row {
				margin-bottom: 15px;
			}
			a {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	&.common-home {
		.langs {
			margin-top: 0;
		}
	    section.module {
	        @include make-col(7.55);
	        margin-left: auto;
	        margin-right: auto;
	        margin-top: 67px;	    
	        &>.row {
	            flex-flow: row wrap;
	            position: relative;	    
	            .col-vertical {
	                @include make-col(1);
	                position: absolute;
	                left: 0;
	                transform: translateX(-100%) rotate(180deg);
	                writing-mode: tb-rl;
	            }	    
	            .col-auto {
	                @include make-col(12);
	            }
	        }	    
	        .col-vertical {
	            h1, h2, h3, h4, h5, h6 {
	                font-family: $astyEB;
	                margin: 0;
	                font-size: 62px;
	                line-height: 74px;
	                color: black;
	            }
	        }
	    }
	}
}
@import 'responsive'