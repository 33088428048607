@font-face {
	font-family: "Roboto-Black";
	src: url("../fonts/Roboto/Roboto-Black.ttf");
}
@font-face {
	font-family: "Roboto-Bold";
	src: url("../fonts/Roboto/Roboto-Bold.ttf");
}
@font-face {
	font-family: "Roboto-Light";
	src: url("../fonts/Roboto/Roboto-Light.ttf");
}
@font-face {
	font-family: "Roboto-Medium";
	src: url("../fonts/Roboto/Roboto-Medium.ttf");
}
@font-face {
	font-family: "Roboto-Regular";
	src: url("../fonts/Roboto/Roboto-Regular.ttf");
}
@font-face {
	font-family: "RobotoCondensed";
	src: url("../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf");
}
@font-face {
	font-family: "RobotoCondensed-Bold";
	src: url("../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf");
}
@font-face {
	font-family: "RobotoCondensed-Light";
	src: url("../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf");
}
@font-face {
	font-family: "AstyBlack";
	src: url("../fonts/Asty/AstyCFStd-Black.otf");
}
@font-face {	
	font-family: "AstyBold";
	src: url("../fonts/Asty/AstyCFStd-Bold.otf");
}
@font-face {	
	font-family: "AstyBook";
	src: url("../fonts/Asty/AstyCFStd-Book.otf");
}
@font-face {	
	font-family: "AstyExtraBold";
	src: url("../fonts/Asty/AstyCFStd-ExtraBold.otf");
}
@font-face {	
	font-family: "AstyLight";
	src: url("../fonts/Asty/AstyCFStd-Light.otf");
}
@font-face {	
	font-family: "AstyMedium";
	src: url("../fonts/Asty/AstyCFStd-Medium.otf");
}