body {
	font-family: $roboto;
	color: #777;
	color: #666;
	font-size: 16px;

	&.menu_opened {
		overflow: hidden !important;
	}
}
h1, h2, h3, h4, h5, h6 {
	color: #444;
}
.fa {
    font-size: 18px;
    color: $primaryColor;
}
h1 {
	font-size: 33px;
}
h2 {
	font-size: 27px;
}
h3 {
	font-size: 21px;
}
h4 {
	font-size: 15px;
}
h5 {
	font-size: 12px;
}
h6 {
	font-size: 10.2px;
}
a {
	color: #000;
}
a:hover {
    text-decoration: none;
    color: #000;
}
legend {
	font-size: 18px;
	padding: 7px 0px
}
label {
	font-weight: normal;
}
.text-danger {
	color: $deepRed;
}
.pull-left {
	float: left;
}
.pull-right {
	float: right;
}
a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
.bg-lightgray {
	background-color: #ECECEC;
}
.cursor-pointer {
	cursor: pointer;
}
.text-underline {
	text-decoration: underline;
}
.buttons {
	margin-top: 2em;
}
.text-black {
	color: black;
}
.bg-gold {
	background-color: gold;
}
.bg-black {
	background-color: black;
}
button, a {
    &:hover, &:focus, &:focus:not(:focus-visible) {
    	outline: 0;
    	box-shadow: none;
    }
}
ul.pagination {
	margin-top: 3rem;
	border-bottom: 2px solid;
	border-radius: 0;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	li {
		flex: 50px;
		display: flex;
		justify-content: center;
		max-width: 50px;
		a, span {
			width: 100%;
			text-align: center;
			font-size: 1.25rem;
			font-weight: 600;
			padding: .9rem .6rem;
		}
	}
}
#vertical_placeholder {
	padding: 3.3rem;
	width: 100%;
	display: block;
}
.breadcrumb {
	border-bottom: 1px solid;
	padding: 0;
	color: #000;
	text-transform: uppercase;

	li {		
		&:after {
			content: '/';
	       	display: inline-block;
			color: black;
			margin-left: 5px;
			margin-right: 5px;
		}
		&:last-child {
			&:after {
				content: none;
			}
		}
		a {
			font-family: Roboto-Medium;
		    color: #000;
		    line-height: 19px;
		    font-size: 16px;

		    @include media-breakpoint-down(sm) {
		    	font-size: 14px;
		    }
		}
	}
}
#logo {
    margin: 0 0 10px 0;
}

#main {
    margin-top: 15px;

	&>[id] {
		position: relative;
	}
}

.alert{
	position: absolute;
	top: 0;
	margin-bottom: 0;
	z-index: $z-index-alert;

	a {
		font-weight: 600;
	}
}

.alert-success, .alert-danger {
	background-color: #ececec;
	border-color: #ececec;
	color: #000;
	text-align: center;
	width: auto;
	left: 0;
	right: 0;
	z-index: 99999;
}

.required .col-form-label:before {
    content: '* ';
    color: #F00;
    font-weight: bold;
}

.img-thumbnail {
	max-height: 100px;
	border: 0;
	border-radius: 0;
	padding: 0;
}

.panel {
	&.panel-default {
		border-bottom: 2px solid $primaryColor;
		padding: 25px 15px;

		.panel-default {
			border-bottom: 2px solid $primaryColor;
			padding: 25px 15px;
		}

		select,
		textarea,
		input[type="text"],
		input[type="password"] {
			border: 1px solid $primaryColor;
		}
	}
}

.accordion-item {
	border-bottom: 0;

	.accordion-header {
		border-bottom: 2px solid $primaryColor;
		padding: 25px 15px;
	}
	.accordion-button {
		background-color: transparent;
		color: $primaryColor;
		box-shadow: none;
		padding: 0;
	}
	select,
	textarea,
	input[type="text"],
	input[type="password"] {
		border: 0;
		border-radius: 0;
		border-bottom: 1px solid $primaryColor;
		margin-bottom: 1em;
	}
}

.divider {
    border-top: 3px solid $primaryColor;
}

.terms {
	line-height: 35px;
	display: inline-flex;
	align-items: center;

	@include media-breakpoint-between(xs, lg) {
		line-height: 20px;
		margin-top: 15px;
	}

	input {
		margin-left: 5px;
		margin-right: 10px;
	}
}

.cc-window.cc-floating {
	border-radius: 30px;
}

#toTopContainer {
	text-align: right;
	height: 0px;
	width: 96px;
	top: calc(100vh - 320px);
	right: 20px;

	&.sticky {
		top: calc(100vh - 120px);
		opacity: 1;
		position: fixed;
		height: 50px;
		z-index: 6;
		transition: all 0.3s ease;

		#scrollToTop {
			display: block;
		}
	}

	#scrollToTop {
		display: none;
		width: 50px;
		height: 50px;
		border: 0px;
		border-radius: 50px;
		-webkit-appearance: none;
		-moz-appearance: none;
		box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);
		-moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);
		-webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);

		i {
			font-size: 1.5rem;
		}
	}
}
.dropdown-menu.show {
	opacity: 1 !important;
	display: block !important;
}
.btn {
	&-primary {
		background-color: $deepRed;
		color: $white;
		border: 2px solid $deepRed;
		font-weight: 600;
		border-radius: 0;

		&:hover, &:active {
			background-color: black;
			color: white;
			border-color: rgba(185, 155, 86, .5);
		}
		
		&:focus {
			box-shadow: 0 0 0 .2rem rgba(185, 155, 86, .5);
			border-color: $deepRed;
			background-color: black;
			color: $white;
		}
	}
	&-default {
		border: 2px solid $black;
		font-weight: 600;
		border-radius: 0;
		&:hover, &:active {
			background-color: $deepRed;
			color: white;
			border-color: rgba(185, 155, 86, .5);
		}
	}
}

.hc-offcanvas-nav {
	z-index: $z-index-menu + 1000;
	.nav-content {
		&> h2, h3, h4, h5, h6 {
			color: black;
		}

		.nav-title-btns {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			column-gap: 5%;
			row-gap: 20px;

			.btn {
				border-radius: 0;
				border: 1px solid black;
				font-size: 21px;
				font-family: $roboto;

				&:active {
					background-color: black;
					color: white;
				}
			}
		}
	}
}

.hc-offcanvas-nav .nav-close-button span {
	&::before {
		margin-left: -6px;
	}
}

.company-aside {
	position: fixed;
	z-index: $z-index-modal;
	background-color: #D2D2D2;
	right: 0;
	top: 0;
	transform: translateX(100%);
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	transition: all .33s ease-in-out;
	padding-bottom: 20px;

	&.show {
		transform: translateX(0);
		overflow: auto;
	}

	.container-fluid {
		& > .row {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.row-top {
		margin-top: 20px;
		.btn-company {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 50px;
			border-radius: 0;
		}
		.close.icon {
			left: unset;
			right: 0;
		}
	}

	.row-links {
		margin-top: 50px;
		margin-bottom: 25px;

		a {
			font-family: $robotoMedium;
			font-size: 25px;
			line-height: 30px;
		}
	}

	.row-newsletter-header {
		label {
			span {
				&:last-child {
					font-size: 11px;
				}
			}
		}
	}

	.row-info {
		margin-top: 60px;
		padding-left: var(--row-padding-x);
		padding-right: var(--row-padding-x);

		a {
			font-family: $robotoCBold;
			font-size: 16px;
			border-radius: 23px;
			border: 2px solid black;
			padding-top: 10px;
			padding-bottom: 10px;

			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}

	.row-bottom {
		padding-left: var(--row-padding-x);
		padding-right: var(--row-padding-x);

		.btn-group {
			border-radius: 23px;
			border: 2px solid black;
			padding-left: 25px;
			padding-right: 25px;

			.btn {
			}

			input[type="radio"] {
				appearance: none;
				display: none;
				// &:first-child {
				//     border-right: 2px solid black;
				// }
			}
		}
	}

	.social {
		a {
			font-family: $robotoMedium;
			font-size: 17px;
			color: black;

			&:hover {
				color: $secondaryColor;
			}
		}
	}
}

.dwhite-prod-link {	
	.dwhite {
		color: currentColor;
		text-shadow: 1px 1px 0 #BDD530, 2px 2px 0 #FDB814, 3px 3px 0 #E90A08, 4px 4px 0 #108E24;
		margin-right: 5px;
		position: relative;
		top: -1px;
	}
}

.dwhite-modal-backdrop {
	position: fixed;
    top: 0;
    left: 0;
    z-index: $z-index-modal + 1;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: $primaryColor, $alpha: .6);
	transition: opacity .3 ease-in;
	
	&.backdrop-hidden {
		width: 0;
		height: 0;
		left: 0;
		opacity: 0;
		overflow: hidden;
	}
}
.centered-absolute {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}