#checkout-cart {
	a:not(.btn) {
		color: #444;
	}

	.input-group {
		.form-control[name^="quantity"] {
			border: 1px solid $primaryColor;
			border-radius: 0;
		}
	}
	.btn-update {
		margin-left: 5px;
		margin-right: 2px;
		height: 38px;
	}

	.btn-primary {
		min-height: 38px;
	}

	input[type="text"],
	select {
		border-radius: 0;
		border: 1px solid $primaryColor;
    }
    
    .buttons {
        display: flex;
        justify-content: space-between;

        .btn-default {
            border: 1px solid black;
        }
    }
    
    .cart {
        margin-bottom: 2.5rem;
    }
    .cart__product {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 1rem 0 0.5rem;
        border-top: 1px solid #dde2e6;
        border-bottom: 1px solid rgba(221, 226, 230, 0.5);
        padding: 10px 0px;
    
        &__image {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
    
        &__details {
            flex: 0 0 75%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
    
            &__info {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
    
                &__main {
                    margin-bottom: 0.75rem;
                }
    
                &__price {
                    font-size: 0.85rem;
                }
    
                &__name {
                    color: $primaryColor;
                    font-size: 1rem;
                }
    
                &__span {
                    font-size: 0.65rem;
                }
            }
    
            &__actions {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
    
                &__input {
                    width: 80px;
                }
    
                button {
                    margin: 0px 0.5rem;
                }
            }
        }
    } 
}
.cart-aside {
    height: 100%;
    width: 24.5%;
    position: fixed;
    z-index: 201111;
    top: 0;
    right: 0;
    transform: translateX(100%);
    background-color: $white;
    color: $primaryColor;
    overflow-x: hidden;
    overflow-y: scroll;
    border: 2px solid $primaryColor;
    transition: .25s linear;

    @include thin-scollbar;

    &.show {
        transform: translateX(0);
    }
}
.cart {
    .fa-times {
        font-size: 1.5em;
    }
    &__header {
        text-align: center;
        padding: 2em 6.36% 2em;

        svg.close-trigger {
            cursor: pointer;
        }

        h2 {
            color: black;
            font-size: 34px;
            font-family: $robotoBold;
            line-height: 17px;
        }
        
        .title {
            color: $primaryColor;
            font-size: 5vw;
            line-height: 37px;
        }

        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            background-color: white;
            color: white;
            width: 56px;

            img[src*="cart"] {
                height: 21px;
            }
        }

        &>span {
            color: $primaryColor;
            font-family: $robotoBold;
            font-size: 20px;
        }
    }
    ul {
        padding-left: 7.94%;
        padding-right: 7.94%;
        color: $primaryColor;
        font-size: 15px;
        font-family: $robotoMedium;

        @include media-breakpoint-down(md) {
            padding-right: 0;
            padding-left: 5px;
        }
    }
}
.product_counter {
    position: absolute;
    right: 4em;
    bottom: 2em;
    font-size: 12px;
    color: $white;
    transform: translate(100%, -60%);
    background-color: $deepRed;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @include media-breakpoint-down(lg) {
        bottom: auto;
        right: 8em;
        top: 3em;
        width: 20px;
        height: 20px;
    }
    @include media-breakpoint-down(sm) { 
        right: 5em;
    }
}