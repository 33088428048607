.header {
    &--desktop {
        position: fixed;
        z-index: -1;
        top: 0;
        left: var(--aside-left-width);
        max-width: calc(100% - var(--aside-left-width));
        .logo {
            margin-top: -3vh;            
            justify-content: center;
            letter-spacing: 15.2px;
            font-family: $astyBlack;
            h1 {
                font-size: 8.89vw;
                color: $black;
            }
        }
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    &--mobile {
        padding: 0 30px;
        border-bottom: 1px solid #e0e0e0;
        position: fixed;
        z-index: 1000;
        background: $white;

        .nav-langs {
            .btn--aside {
                padding: 0;
                border: 0;
                margin-bottom: 5px;
            }
            span {
                color: $deepRed;
                border-bottom: 2px solid;
            }
            .btn-group {
                label:first-child {
                    margin-right: 1em;
                }
            }
        }

        div.col-md-4.col-3:first-child {
            display: flex;
            flex-direction: row;
            #mobile-menu-trigger {
                position: relative;
                margin-right: 12px;
                min-width: 20px;
                .menu.icon {
                    margin-top: 9.5px;
                    position: relative;
                }
            }
            .search-container {
                display: inline-block;
                img {
                    max-width: 30px;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            display: none;
        }
        @include media-breakpoint-down(sm) {
            padding: 0 12px;
            .nav-pills {
                .nav-link {
                    padding: .5rem .8rem;
                }
            }
        }        
    }
}
.langs {
    /*margin-top: -7.5em;*/
    span {
        color: $deepRed;
        border-bottom: 2px solid;
    }
    .btn-group {
        label:first-child {
            margin-right: 1em;
        }
    }
} 
.espa-fixed {
    bottom: 0;
    left: 0;
    z-index: 1;
}
.searchmodal{
    overflow: hidden;
    &.btn--aside {
        text-transform: capitalize;
        color: #444;
        font-family: $robotoMedium;
        font-size: 16px;
        .fas {
            color: $black;
        }
    }
} 
#search {
    label {
        font-size: xx-small;
        color: $white;
        margin: 0;
        height: 0;
    }
    .form-control{
        border: none;
        z-index: 100;
        font-size: 3em;
        height: 60px;
        -webkit-transition: width 0.3s;
        -moz-transition: width 0.3s;
        transition: width 0.3s;
        letter-spacing: 1px;
        &:active, &:focus {
            box-shadow: none;
        }
    }
}
#modalSearch {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin-top: 0;
    background: rgba(255, 255, 255, 0.64);
    .modal-content {
        border: 0;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none;
    }
    .modal-header {
        border: 0;
        padding-bottom: 0;
        justify-content: flex-end;
    }
    .close {
        font-size: 3em;
        color: $black;
        opacity: 1;
    }
}
#d_ajax_search_results {
    position: absolute;
    top: 100%;
    width: 100%;
    padding: 1em 3rem 0;
    margin-top: 0;
    background-color: $white;
    border: 2px solid #ddd;
    .name {
        span {
            &:hover {
                color: $deepRed;
            }
        }
    }
    .result-link {
        align-items: center;
    }
    .old-price {
        text-decoration: line-through red;
        color: #777;
        margin-right: 8px;
    }
    .all-results {
        text-align: center;
        a {
            color: $deepRed;
            font-weight: 600;
        }
    }
}
@include media-breakpoint-down(lg) {
    header {
        min-height: 100px;
        display: inline-block;
        position: relative;
    }
    .col-menu {
        display: inline-flex;
        .menu {
            &.icon {
                color: $primaryColor;
                position: relative;
                top: 6px;
                margin-right: 12px;
                margin-top: 0;
                width: 24px;
                height: 2px;
                background-color: currentColor;

                &:before {
                    content: '';
                    position: absolute;
                    top: -7px;
                    left: 0;
                    width: 24px;
                    height: 2px;
                    background-color: currentColor;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 7px;
                    left: 0;
                    width: 24px;
                    height: 2px;
                    background-color: currentColor;
                }  
            }
        }
    }
    .hc-offcanvas-nav {
        z-index: $z-index-menu + 1000;
        .nav-wrapper, ul {
            background-color: $white;
        }
        .nav-close-button span {
            &::before {
                margin-left: -6px;
            }
        }
        .nav-content {
            &> h2, h3, h4, h5, h6 {
                color: black;
            }
            >.nav-close:first-child a {
                background-color: $white;
            }
            .nav-title-btns {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                column-gap: 5%;
                row-gap: 20px;

                .btn {
                    border-radius: 0;
                    border: 1px solid black;
                    font-size: 21px;
                    font-family: $roboto;

                    &:active {
                        background-color: black;
                        color: white;
                    }
                }
            }
        }
    }
    .hc-nav-trigger {
        top: 3px;
        position: relative;
        display: inline-flex;
    }
    .home-landing-banner {
        .swiper-pager {
            .swiper-button-next {
                font-size: 22px;
                right: -10px;                
            }
            .swiper-button-prev {                
                left: -10px;
            }
        }
    }
    .swiper-button-next, .swiper-button-prev { 
        &:after, &:before {
            font-size: 22px;
        }
    }
}
@include media-breakpoint-down(md) {
    .home-landing-banner {
        .swiper-pager {
            .swiper-button-next {
                right: -10px;
            }
            .swiper-button-prev {
                left: -10px;
            }
        }
    }
    #search .form-control {
        font-size: 2em;
    }
    #modalSearch {
        margin: 0;
        .modal-header {
            padding-top: 0;
        }
        .close {
            font-size: 2em;
        }
    }
}