@mixin keyframe ($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

@mixin animation ($delay, $duration, $animation, $iteration, $direction: normal, $fillmode: forwards) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: $fillmode;
    -webkit-animation-direction: $direction;
    -webkit-animation-iteration-coun: $iteration;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: $fillmode;
    -moz-animation-direction: $direction;
    -moz-animation-iteration-coun: $iteration;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: $fillmode;
    animation-direction: $direction;
    animation-iteration-count: $iteration;
}

@mixin thin-scollbar() {
    &::-webkit-scrollbar {
        width: 12.5px;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 20px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: rgb(164, 163, 163);
        border-radius: 10px;
    }
}